import { useEffect, useRef, useState } from 'react';
import { Box, useToast } from '@chakra-ui/react';

import { MemoizedHistoryPointPopup } from 'components/HistoryPointPopup';
import { MemoizedBLEHistoryPointPopup } from 'components/BLEHistoryPointPopup';
import { MemoizedMultiplePoisPopup } from 'components/MultiplePoisPopup';
import { MemoizedMultipleDevicesPopup } from 'components/MultipleDevicesPopup';
import { MemoizedMultipleBleDevicesPopup } from 'components/MultipleBleDevicesPopup';

import { useSelectedBleDevice } from 'hooks/useSelectedBleDeviceNotice';
import mapHistoryStore from 'hooks/useMapHistory';
import { useMapInstance } from 'hooks/useMapInstance';
import { useSelectedPoi } from 'hooks/useSelectedPoi';
import { useSelectedDevice } from 'hooks/useSelectedDevice';
import { limitLatitude } from 'utils/map';
import { mapConfig } from 'consts/map';
import { PoiType, SystemPoiSubtype } from 'consts/pois';
import { GeofenceQuerySchema } from 'api/models/GeofenceQuerySchema';
import { PoiGeohashSchema } from 'api/models/PoiGeohashSchema';
import { DeviceGeohashSchema } from 'api/models/DeviceGeohashSchema';
import { BLEDeviceGeohashSchema } from 'api/models/BLEDeviceGeohashSchema';

import { theme } from 'styles/theme';
import bleIcon from 'assets/icons/circle-ble.svg';
import deviceIcon from 'assets/icons/circle-primary.svg';
import poiAirportIcon from 'assets/icons/poi-airport.svg';
import poiIcon from 'assets/icons/poi-marker.svg';
import poiPartnerIcon from 'assets/icons/poi-partner.svg';
import poiPortIcon from 'assets/icons/poi-port.svg';
import poiTripIcon from 'assets/icons/poi-trip.svg';

import { useMapHistory } from './hooks/useMapHistory';
import { Popup, PopupType } from './components/Popup';

type MapProps = {
  deviceGeohashes?: DeviceGeohashSchema[];
  poiGeohashes?: PoiGeohashSchema[];
  bleDevicesGeohashes?: BLEDeviceGeohashSchema[] | null;
  onMapUpdate: (params: GeofenceQuerySchema, options: { delay?: boolean }) => void;
};

/**
 * Returns appropriate icon for a given POI's type
 */
const getPoiIcon = (type: string, subtype: string): string => {
  if (type === PoiType.partner) return poiPartnerIcon;
  if (type === PoiType.trip) return poiTripIcon;

  if (subtype === SystemPoiSubtype.airport) return poiAirportIcon;
  else if (subtype === SystemPoiSubtype.port) return poiPortIcon;

  return '';
};

export const Map = ({ bleDevicesGeohashes, deviceGeohashes, poiGeohashes, onMapUpdate }: MapProps) => {
  const toast = useToast();
  const map = useRef<google.maps.Map>();
  const deviceMarkers = useRef<google.maps.Marker[]>([]);
  const poiMarkers = useRef<google.maps.Marker[]>([]);
  const poiAccuracyCircles = useRef<google.maps.Circle[]>([]);
  const bleDeviceMarkers = useRef<google.maps.Marker[]>([]);
  const multipleDevicesPopup = useRef<PopupType>();
  const multipleBleDevicesPopup = useRef<PopupType>();
  const multiplePoisPopup = useRef<PopupType>();
  const maxZoomService = useRef<google.maps.MaxZoomService>();

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const multipleDevicesPopupRef = useRef<HTMLDivElement | null>(null);
  const multipleBleDevicesPopupRef = useRef<HTMLDivElement | null>(null);
  const multiplePoisPopupRef = useRef<HTMLDivElement | null>(null);

  const [multipleDevicesList, setMultipleDevicesList] = useState<number[]>([]);
  const [multiplePoisList, setMultiplePoisList] = useState<string[]>([]);
  const [multipleBleDevicesList, setMultipleBleDevicesList] = useState<string[]>([]);

  const {
    state: { isPathVisible, deviceHistory, isBLEPathVisible, bleDeviceHistory },
  } = mapHistoryStore();

  const { setMapInstance, visibleMapItems } = useMapInstance();
  const { setSelectedPoi } = useSelectedPoi();
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { selectedBleDevice, setSelectedBleDevice } = useSelectedBleDevice();

  /**
   * Handles changing the map bounds.
   */
  const updateMap = (delay?: boolean) => {
    // Get bounds of visible part of the map
    const mapBounds = map.current?.getBounds();
    const topLeftCorner = {
      lat: mapBounds?.getNorthEast().lat() ?? 90,
      lng: mapBounds?.getSouthWest().lng() ?? -180,
    };
    const bottomRightCorner = {
      lat: mapBounds?.getSouthWest().lat() ?? -90,
      lng: mapBounds?.getNorthEast().lng() ?? 180,
    };

    // Calculate precision based on distance between the bounds
    const from = new google.maps.LatLng(topLeftCorner);
    const to = new google.maps.LatLng(bottomRightCorner);
    const distance = google.maps.geometry.spherical.computeDistanceBetween(from, to);
    const precision = `${Math.round(distance)}m`;

    // Propagate updated map params
    onMapUpdate(
      {
        precision,
        top_left: Object.values(topLeftCorner),
        bottom_right: Object.values(bottomRightCorner),
      },
      { delay }
    );
  };

  const handleEmpty = () => {
    toast({
      description: 'No historical data for given time range.',
      status: 'info',
      isClosable: true,
    });
  };

  const history = useMapHistory(map, updateMap, isPathVisible, deviceHistory, handleEmpty);
  const bleHistory = useMapHistory(map, updateMap, isBLEPathVisible, bleDeviceHistory, handleEmpty);

  /**
   * Removes device markers from the map.
   */
  const removeDeviceMarkers = () => {
    for (let i = 0; i < deviceMarkers.current.length; i++) {
      deviceMarkers.current[i].setMap(null);
    }
    deviceMarkers.current = [];
  };

  /**
   * Removes POI markers from the map.
   */
  const removePoiMarkers = () => {
    for (let i = 0; i < poiMarkers.current.length; i++) {
      poiMarkers.current[i].setMap(null);
    }
    poiMarkers.current = [];
  };

  /**
   * Removes POI accuracy circles from the map.
   */
  const removePoiAccuracyCircles = () => {
    for (let i = 0; i < poiAccuracyCircles.current.length; i++) {
      poiAccuracyCircles.current[i].setMap(null);
    }
    poiAccuracyCircles.current = [];
  };
  /**
   * Removes POI accuracy circles from the map.
   */
  const removeBLEDeviceMarkers = () => {
    for (let i = 0; i < bleDeviceMarkers.current.length; i++) {
      bleDeviceMarkers.current[i].setMap(null);
    }
    bleDeviceMarkers.current = [];
  };

  /**
   * Initializes Google Maps.
   */
  useEffect(() => {
    // Initialize the map and popups
    if (!(multipleDevicesPopup.current && multipleBleDevicesPopup.current && multiplePoisPopup.current)) {
      const gMap = new google.maps.Map(mapContainerRef.current as HTMLDivElement, mapConfig);
      multipleDevicesPopup.current = new Popup(multipleDevicesPopupRef.current as HTMLDivElement);
      multipleBleDevicesPopup.current = new Popup(multipleBleDevicesPopupRef.current as HTMLDivElement);
      multiplePoisPopup.current = new Popup(multiplePoisPopupRef.current as HTMLDivElement);

      // Add event listeners to the map
      gMap.addListener('projection_changed', updateMap);
      gMap.addListener('dragend', updateMap);
      gMap.addListener('zoom_changed', () => updateMap(true));

      // Define maximum zoom imagery service
      maxZoomService.current = new google.maps.MaxZoomService();

      // Save the map to the instance variable and to the context
      map.current = gMap;
      setMapInstance(gMap);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Draws device markers on the map (or only one marker when it is selected).
   */
  useEffect(() => {
    const singleMarkerSize = 18;
    const groupMarkerSize = 28;

    // Remove all the current device markers
    removeDeviceMarkers();

    // Check if devices should be visibile
    if (!visibleMapItems.device) {
      return;
    }

    if (selectedDevice?.location) {
      // Draw one marker on the map according to the selected device
      const marker = new google.maps.Marker({
        position: { lat: limitLatitude(selectedDevice.location.lat), lng: selectedDevice.location.lon },
        icon: {
          url: deviceIcon,
          scaledSize: new google.maps.Size(singleMarkerSize, singleMarkerSize),
          anchor: new google.maps.Point(singleMarkerSize / 2, singleMarkerSize / 2),
        },
        map: map.current,
      });
      deviceMarkers.current.push(marker);
    } else {
      // Draw all device markers on the map
      deviceGeohashes?.forEach((geohash) => {
        const isGroupMarker = geohash.count > 1;
        const markerSize = isGroupMarker ? groupMarkerSize : singleMarkerSize;
        const markerOffset = Math.round(markerSize / 2);
        const marker = new google.maps.Marker({
          position: { lat: limitLatitude(geohash.lat), lng: geohash.lon },
          label: isGroupMarker
            ? { text: geohash.count.toString(), color: 'white', fontFamily: 'Open Sans', fontSize: '14' }
            : '',
          icon: {
            url: deviceIcon,
            scaledSize: new google.maps.Size(markerSize, markerSize),
            anchor: new google.maps.Point(markerOffset, markerOffset),
          },
          map: map.current,
        });

        // Add the click event to the device marker
        marker.addListener('click', () => {
          if (isGroupMarker) {
            const markerPosition = marker.getPosition() as google.maps.LatLng;
            const mapZoom = map.current?.getZoom();

            // Show the multiple devices popup or zoom in the map
            maxZoomService.current?.getMaxZoomAtLatLng(markerPosition, (response) => {
              if (response.status === 'OK' && mapZoom && response.zoom > mapZoom) {
                map.current?.setCenter(markerPosition);
                map.current?.setZoom(mapZoom + 1);
              } else {
                setMultipleDevicesList(geohash.devices);
                multipleDevicesPopup.current?.setMap(map.current || null);
                multipleDevicesPopup.current?.setPosition(markerPosition);
              }
            });
          } else {
            setSelectedDevice(geohash.top);
          }
        });

        deviceMarkers.current.push(marker);
      });
    }
  }, [deviceGeohashes, selectedDevice, visibleMapItems.device]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Draws device markers on the map (or only one marker when it is selected).
   */
  useEffect(() => {
    const singleMarkerSize = 18;
    const groupMarkerSize = 28;

    // Remove all the current device markers
    removeBLEDeviceMarkers();

    // Check if ble devices should be visibile
    if (!visibleMapItems.bleDevice) {
      return;
    }

    if (selectedBleDevice?.location) {
      // Draw one marker on the map according to the selected device
      const marker = new google.maps.Marker({
        position: { lat: limitLatitude(selectedBleDevice.location.lat), lng: selectedBleDevice.location.lon },
        icon: {
          url: bleIcon,
          scaledSize: new google.maps.Size(singleMarkerSize, singleMarkerSize),
          anchor: new google.maps.Point(singleMarkerSize / 2, singleMarkerSize / 2),
        },
        map: map.current,
      });
      bleDeviceMarkers.current.push(marker);
    } else {
      bleDevicesGeohashes?.forEach((geohash) => {
        const isGroupMarker = geohash.count > 1;
        const markerSize = isGroupMarker ? groupMarkerSize : singleMarkerSize;
        const markerOffset = Math.round(markerSize / 2);
        const marker = new google.maps.Marker({
          position: { lat: limitLatitude(geohash.lat), lng: geohash.lon },
          label: isGroupMarker
            ? { text: geohash.count.toString(), color: 'white', fontFamily: 'Open Sans', fontSize: '14' }
            : '',
          icon: {
            url: bleIcon,
            scaledSize: new google.maps.Size(markerSize, markerSize),
            anchor: new google.maps.Point(markerOffset, markerOffset),
          },
          map: map.current,
        });

        // Add the click event to the device marker
        marker.addListener('click', () => {
          if (isGroupMarker) {
            const markerPosition = marker.getPosition() as google.maps.LatLng;
            const mapZoom = map.current?.getZoom();

            // Show the multiple devices popup or zoom in the map
            maxZoomService.current?.getMaxZoomAtLatLng(markerPosition, (response) => {
              if (response.status === 'OK' && mapZoom && response.zoom > mapZoom) {
                map.current?.setCenter(markerPosition);
                map.current?.setZoom(mapZoom + 1);
              } else {
                setMultipleBleDevicesList(geohash.ble_devices);
                multipleBleDevicesPopup.current?.setMap(map.current || null);
                multipleBleDevicesPopup.current?.setPosition(markerPosition);
              }
            });
          } else {
            setSelectedBleDevice(geohash.top);
          }
        });

        bleDeviceMarkers.current.push(marker);
      });
    }
  }, [bleDevicesGeohashes, selectedBleDevice, visibleMapItems.bleDevice]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Draws POI markers on the map.
   */
  useEffect(() => {
    const mapZoom = map.current?.getZoom();

    // Remove all the current POI markers and accuracy circles
    removePoiMarkers();
    removePoiAccuracyCircles();

    // Check if pois and accuracy circles should be visibile
    if (!visibleMapItems.poi) {
      return;
    }

    // Draw POI markers on the map
    poiGeohashes?.forEach((geohash) => {
      const isGroupMarker = geohash.count > 1;
      const markerSize = 34;
      const marker = new google.maps.Marker({
        position: { lat: limitLatitude(geohash.lat), lng: geohash.lon },
        label: isGroupMarker
          ? { text: geohash.count.toString(), color: theme.colors.primary, fontFamily: 'Open Sans', fontSize: '14' }
          : '',
        icon: {
          url: isGroupMarker ? poiIcon : getPoiIcon(geohash.top.type, geohash.top.subtype),
          scaledSize: new google.maps.Size(markerSize, markerSize),
          labelOrigin: new google.maps.Point(markerSize / 2, 13),
        },
        map: map.current,
      });

      poiMarkers.current.push(marker);

      if (mapZoom && mapZoom >= 8 && !isGroupMarker) {
        const accuracyCircle = new google.maps.Circle({
          strokeColor: theme.colors.primary,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: theme.colors.primary,
          fillOpacity: 0.2,
          map: map.current,
          center: marker.getPosition(),
          radius: geohash.top.radius,
        });
        poiAccuracyCircles.current.push(accuracyCircle);
      }

      marker.addListener('click', () => {
        if (isGroupMarker) {
          const markerPosition = marker.getPosition() as google.maps.LatLng;
          const mapZoom = map.current?.getZoom();

          // Show the multiple POIs popup or zoom in the map
          maxZoomService.current?.getMaxZoomAtLatLng(markerPosition, (response) => {
            if (response.status === 'OK' && mapZoom && response.zoom > mapZoom) {
              map.current?.setCenter(markerPosition);
              map.current?.setZoom(mapZoom + 1);
            } else {
              setMultiplePoisList(geohash.pois);
              multiplePoisPopup.current?.setMap(map.current || null);
              multiplePoisPopup.current?.setPosition(markerPosition);
            }
          });
        } else {
          setSelectedPoi(geohash.top);
        }
      });
    });
  }, [poiGeohashes, visibleMapItems.poi]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box ref={mapContainerRef} height="full" />
      <MemoizedMultiplePoisPopup poiIds={multiplePoisList} ref={multiplePoisPopupRef} />
      <MemoizedMultipleBleDevicesPopup bleDeviceIds={multipleBleDevicesList} ref={multipleBleDevicesPopupRef} />
      <MemoizedMultipleDevicesPopup deviceIds={multipleDevicesList} ref={multipleDevicesPopupRef} />
      <MemoizedHistoryPointPopup historyPoint={history.point} ref={history.pointPopupRef} />
      <MemoizedBLEHistoryPointPopup bleHistoryPoint={bleHistory.point} ref={bleHistory.pointPopupRef} />
    </>
  );
};
