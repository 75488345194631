import { maxLatitude, minLatitude } from 'consts/map';

/**
 * Limits a latitude if it is outside real bounds.
 * @param {number} latitude
 * @returns {number}
 */
export const limitLatitude = (latitude: number) => {
  let lat = latitude;

  if (lat < minLatitude) lat = minLatitude;
  else if (lat > maxLatitude) lat = maxLatitude;

  return lat;
};
